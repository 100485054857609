<template>
  <div>
    <a-card :bordered="false">
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="退货商品列表"
        :sub-title="this.queryParam.refund_no"
        @back="() => $router.back()"
      >
      </a-page-header>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="min-width: 110px">
          {{ (text / 100).toFixed(2) }}
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a v-if="!record.is_warehoused" @click="handleEdit(record)">退货</a>
            <a-popconfirm
              v-if="false"
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
      <refund-goods-form
        v-if="visible"
        ref="refundGoodsModal"
        :visible="visible"
        :model="mdl"
        @cancel="handleRefundCancel"
        @ok="handleOk"
      />
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import { createRefundGoods, deleteRefundGoods, refundGoodsList } from '@/api/counter_shop_order'
import CreateRefundGoodsForm from '@/views/a-applet/shop_manage/deliveryaddress/CreateRefundGoodsForm'
import RefundGoodsForm from '@/views/a-counter/manage/refund/modules/RefundGoodsForm'
import { pick_list_goods_by_order_no, picks_list } from '@/api/c_wms_picks'

export default {
  name: 'TableList',
  components: {
    RefundGoodsForm,
    STable,
    CreateRefundGoodsForm
  },
  data () {
    return {
      loading: false,
      visible: false,
      confirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单号',
          ellipsis: true,
          dataIndex: 'refund_order.order_no'
        },
        {
          title: '退款编号',
          ellipsis: true,
          dataIndex: 'refund_order.refund_no'
        },
        {
          title: '商品名称',
          ellipsis: true,
          dataIndex: 'name'
        },
        {
          title: '价格',
          ellipsis: true,
          dataIndex: 'price',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '数量',
          ellipsis: true,
          dataIndex: 'count'
        },
        {
          title: '时间',
          ellipsis: true,
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return refundGoodsList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.queryParam.refund_no = this.$route.query.refund_no
  },
  methods: {
    handleOk (data) {
      const form = this.$refs.refundGoodsModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log(data)
          const postData = {
            id: this.mdl.id,
            count: this.mdl.count,
            warehouse_id: values.warehouse_id,
            cargo_space_id: values.cargo_space_id,
            order_no: this.mdl.refund_order.order_no,
            barcode: this.mdl.barcode,
            source: 'counter'
          }
          createRefundGoods(postData).then((res) => {
            this.visible = false
            if (res.code === 1000) {
              this.$refs.table.refresh(true)
            }
          })
        } else {
          this.confirmLoading = false
        }
        this.mdl = {}
      })
    },
    handleRefundCancel () {
      this.visible = false
      this.confirmLoading = false
    },
    handleDelete (item) {
      console.log(item)
      deleteRefundGoods(item.id).then((res) => {
        console.log(res, '删除成功')
        this.$refs.table.refresh()
      })
    },
    handleEdit (item) {
      this.mdl = item
      picks_list({ order_no: item.refund_order.order_no }).then(res => {
        if (res.code === 1000 && res.data.entries.length > 0) {
          this.mdl.warehouse_id = res.data.entries[0].warehouse_id
          pick_list_goods_by_order_no(item.refund_order.order_no, { barcode: item.barcode }).then(res => {
            if (res.code === 1000) {
              this.mdl.cargo_space_id = res.data[0].cargo_space.id
            }
          }).finally(() => {
            if (this.mdl.warehouse_id !== undefined && this.mdl.cargo_space_id !== undefined) {
              this.visible = true
              this.confirmLoading = false
            }
          })
        } else {
          this.$message.warning('该订单商品未出库')
        }
      })
    }
  }
}
</script>
